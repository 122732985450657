
import { Frameworks } from '@/store/modules/auth/AuthModule'
import Vue from 'vue'
import Component from 'vue-class-component'
import { mapGetters } from 'vuex'
import { debounce } from 'vue-debounce'
@Component({
  computed: {
    ...mapGetters('BusinessModule', ['getBusinessFrameworks']),
    ...mapGetters('AuthModule', ['getCompanyIdSelected', 'getCompanyFrameworksSelected']),
  },
})
export default class ComplianceComponent extends Vue {
  public getCompanyFrameworksSelected!: Frameworks[]
  private Business = this.$store.state.BusinessModule.BusinessState.businessSelected
  private frameworks: any = this.Business.company
    ? this.Business.company.frameworks.sort((a: any, b: any) => {
        return a.name > b.name ? 1 : -1
      })
    : []
  private role = this.$store.state.AuthModule.AuthState.role
  private ADMIN_ROLES = process.env.VUE_APP_PRIVILEGED_ROLES?.split(',') || []

  private isRole(role: string) {
    return this.$store.state.AuthModule.AuthState.role.includes(role)
  }
  private roleFramework() {
    return this.isRole('Implementador') || this.isRole('Customer Success')
      ? this.getCompanyFrameworksSelected
      : this.frameworks
  }
  private getFrameworkIcon(name: string) {
    if (name.includes('ISO')) {
      return require('@/assets/icons/icon-iso.svg')
    } else if (name.includes('PCI')) {
      return require('@/assets/icons/icon-pci.svg')
    }
  }
  private debouncedSetFrameworkSelected = debounce((framework: Frameworks) => {
    this.$store.commit('PolimorficModule/SET_FRAMEWORK_SELECTED', framework)
  }, 100)
  private goToCompliance(framework: Frameworks) {
    const name = !this.ADMIN_ROLES.some((adminRole) => this.role.includes(adminRole))
      ? 'project'
      : 'category'
    this.$router.push({ name, params: { id: String(framework.id) } })
    this.$store.commit('PolimorficModule/SET_FRAMEWORK_SELECTED', framework)
    this.debouncedSetFrameworkSelected(framework)
  }
}
